import React from "react"
import styled from "styled-components"
import { AppFunctionComponent } from "../../../types"

const DotsWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 10;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    bottom: 30px;
  }
`

const Dot = styled.div<{ active?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.color.dot.hover};
  }
  background-color: ${({ active, theme }) =>
    active ? theme.color.dot.active : theme.color.dot.inactive};
`

export interface Navigation {
  count: number
  index: number
  onIndexChange: (index: number) => void
}

const SliderDots: AppFunctionComponent<Navigation> = ({
  count,
  index,
  onIndexChange,
}) => {
  const moveDot = (innerIndex: number) => {
    onIndexChange(innerIndex)
  }

  return (
    <DotsWrapper>
      {Array.from({ length: count }).map((_, innerIndex) => (
        <Dot
          key={innerIndex}
          onClick={() => moveDot(innerIndex)}
          active={Boolean(index === innerIndex)}
          data-testid="active-dot"
        />
      ))}
    </DotsWrapper>
  )
}

export default SliderDots
