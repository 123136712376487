import React, { MouseEvent, TouchEvent, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import Arrow from "../../images/arrow.inline.svg"
import { Slide } from "./slider-item.component"
import SliderItem from "./slider-item.component"
import { AppFunctionComponent } from "../../types"

const sliderLayoutBreakpoint = 440

const SliderWrapper = styled.div`
  padding: 0;
  overflow: hidden;
  position: relative;
`

const Slider = styled.div`
  margin: 0 auto;
  max-width: 1160px;
  width: calc(100% - 30px);

  @media (min-width: 768px) {
    width: calc(100% - 60px);
  }
`

const slidesList = []

const SliderTrack = styled.div<{ currentSlideIndex: number }>`
  width: 100%;
  display: flex;
  user-select: none;
  flex-direction: row;
  transition: transform 0.5s ease-in-out;

  ${({ currentSlideIndex }) => css`
    --position-Y: ${currentSlideIndex}px;
    --position-X: -${currentSlideIndex * 260}px;

    ${currentSlideIndex === slidesList.length - 1 &&
    css`
      --position-X: calc(-${currentSlideIndex * 260}px + (100% - 245px));
    `};

    @media (min-width: ${sliderLayoutBreakpoint}px) {
      --position-X: calc(
        -${currentSlideIndex * 50}% - ${currentSlideIndex * 7.5}px
      );

      ${currentSlideIndex === slidesList.length - 1 &&
      css`
        --position-X: calc(
          -${(currentSlideIndex - 1) * 50}% - ${(currentSlideIndex - 1) * 7.5}px
        );
      `};
    }

    @media (min-width: 768px) {
      --position-X: calc(
        -${currentSlideIndex * 50}% - ${currentSlideIndex * 15}px
      );

      ${currentSlideIndex === slidesList.length - 1 &&
      css`
        --position-X: calc(
          -${(currentSlideIndex - 1) * 50}% - ${(currentSlideIndex - 1) * 15}px
        );
      `};
    }

    @media (min-width: 1240px) {
      --position-X: calc(-${currentSlideIndex * 600}px);

      ${currentSlideIndex === slidesList.length - 1 &&
      css`
        --position-X: calc(-${(currentSlideIndex - 1) * 600}px);
      `};
    }
  `};
  transform: translate3d(var(--position-X), 0, var(--position-Y));
`

const SlideItem = styled(SliderItem)`
  cursor: grab;
  min-width: 245px;
  max-width: 560px;
  user-select: none;

  &:not(:last-of-type) {
    margin-right: 15px;
  }

  @media (min-width: ${sliderLayoutBreakpoint}px) {
    min-width: calc(50% - 7.5px);
  }

  @media (min-width: 768px) {
    min-width: calc(50% - 15px);
    &:not(:last-of-type) {
      margin-right: 30px;
    }
  }

  @media (min-width: 1240px) {
    min-width: 560px;
    max-width: 560px;
    &:not(:last-of-type) {
      margin-right: 40px;
    }
  }
`

export const ArrowIcon = styled(Arrow)`
  height: 15px;
  user-selectable: none;
  @media (min-width: 960px) {
    height: 22px;
  }
`

export const ArrowContainer = styled.div<{ active: boolean }>`
  top: 50%;
  opacity: 1;
  width: 32px;
  height: 32px;
  display: flex;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  flex-direction: row;
  pointer-events: all;
  visibility: visible;
  user-selectable: none;
  justify-content: center;
  transform: translateY(-50%);
  background-color: ${({ theme }) => theme.color.arrowBackgroundDark};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  @media (min-width: 960px) {
    width: 50px;
    height: 50px;
  }

  ${({ active }) =>
    !active &&
    css`
      opacity: 0;
      visibility: hidden;
    `};
`

const ArrowPrev = styled(ArrowContainer)`
  left: 0;

  svg {
    transform: rotateZ(180deg);
  }
`

const ArrowNext = styled(ArrowContainer)`
  right: 0;
`

export const ArrowsWrapper = styled.div`
  top: 0;
  left: 50%;
  display: flex;
  height: 245px;
  max-width: 1420px;
  position: absolute;
  flex-direction: row;
  pointer-events: none;
  width: calc(100vw - 60px);
  transform: translateX(-50%);
  justify-content: space-between;

  @media (min-width: ${sliderLayoutBreakpoint}px) {
    height: calc(50vw - 30px);
  }

  @media (min-width: 768px) {
    width: calc(100vw - 90px);
    height: calc(50vw - 52.5px);
  }

  @media (min-width: 960px) {
    width: calc(100vw - 120px);
  }

  @media (min-width: 1240px) {
    height: 560px;
    width: 1100px;
  }

  @media (min-width: 1430px) {
    width: calc(100vw - 40px);
  }
`

const useMediaQuery = (breakpoint: number, func: () => void) => {
  useEffect(() => {
    const onResize = () => {
      func()
    }
    const mq = window.matchMedia(`(min-width: ${breakpoint}px)`)
    mq.addListener(onResize)
    return () => {
      mq.removeListener(onResize)
    }
  }, [func])
}

interface Props {
  slides?: Record<string, any>[]
  className?: string
}

const SliderContainer: AppFunctionComponent<Props> = ({
  className,
  slides = [],
}) => {
  const windowAvailable = typeof window !== `undefined`
  const calculateLastPossibleSlideIndex = () => {
    let lastIndex = slides.length - 1
    if (windowAvailable) {
      if (window.innerWidth >= sliderLayoutBreakpoint) {
        lastIndex--
      }
    }
    return lastIndex
  }

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [lastPossibleSlideIndex, setLastPossibleSlideIndex] = useState(
    calculateLastPossibleSlideIndex()
  )

  if (windowAvailable) {
    useMediaQuery(sliderLayoutBreakpoint, () => {
      setLastPossibleSlideIndex(calculateLastPossibleSlideIndex())
    })
  }

  const slide = (i: number) => {
    setCurrentSlideIndex(i)
  }

  const prev = () => {
    let newIndex =
      currentSlideIndex > 0 ? currentSlideIndex - 1 : currentSlideIndex
    if (
      slides.length - 1 === currentSlideIndex &&
      window.innerWidth >= sliderLayoutBreakpoint
    ) {
      newIndex = newIndex - 1
    }
    slide(newIndex)
  }

  const next = () => {
    const lastSlideIndex = calculateLastPossibleSlideIndex()
    const newIndex =
      currentSlideIndex < lastSlideIndex
        ? currentSlideIndex + 1
        : currentSlideIndex

    setLastPossibleSlideIndex(lastSlideIndex)
    slide(newIndex)
  }

  let touchStartX = 0
  let touchStartY = 0

  const touchStart = (e: TouchEvent<HTMLDivElement>) => {
    touchStartX = e.touches[0].clientX
    touchStartY = e.touches[0].clientY
  }

  const touchMove = (e: TouchEvent<HTMLDivElement>) => {
    const touchEndX = e.touches[0].clientX
    const touchEndY = e.touches[0].clientY

    const diffX = Math.abs(touchStartX - touchEndX)
    const diffY = Math.abs(touchStartY - touchEndY)

    if (diffX > 40 && diffY < 30) {
      touchEndX - touchStartX > 0 ? prev() : next()
    }
  }

  let mouseStartX = 0
  let mouseEndX = 0

  const mouseDown = (e: MouseEvent<HTMLDivElement>) => {
    mouseStartX = e.clientX
  }

  const mouseUp = (e: MouseEvent<HTMLDivElement>) => {
    mouseEndX = e.clientX

    const diffX = Math.abs(mouseStartX - mouseEndX)

    if (diffX > 100) {
      mouseEndX - mouseStartX > 0 ? prev() : next()
    }
  }

  return (
    <SliderWrapper
      className={className}
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      onTouchStart={touchStart}
      onTouchMove={touchMove}
    >
      <Slider>
        <SliderTrack currentSlideIndex={currentSlideIndex}>
          {slides.map((slideItem: Slide, index: number) => (
            <SlideItem slide={slideItem} key={index} />
          ))}
        </SliderTrack>
      </Slider>
      <ArrowsWrapper>
        <ArrowPrev onClick={prev} active={currentSlideIndex > 0}>
          <ArrowIcon />
        </ArrowPrev>
        <ArrowNext
          onClick={next}
          active={currentSlideIndex < lastPossibleSlideIndex}
        >
          <ArrowIcon />
        </ArrowNext>
      </ArrowsWrapper>
    </SliderWrapper>
  )
}

export default SliderContainer
