import React, {
  Children,
  createContext,
  useState,
  useContext,
  useLayoutEffect,
} from "react"
import { AppFunctionComponent } from "../../../types"
import SliderNavigation from "./slider-navigation"
import styled, { css } from "styled-components"
import SliderArrows from "./slider-arrows.component"
import SliderDots from "./slider-dots.component"
interface ContextValue {
  count: number
  index: number
  onIndexChange: (index: number) => void
  setCount: (count: number) => void
}
const SliderContext = createContext<ContextValue | null>(null)

export function useSlider() {
  const context = useContext(SliderContext)

  if (!context) {
    throw new Error("useSlider must be used within a Slider")
  }

  return context
}

const Slide = styled.div<{ active: boolean }>`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

type SlidesProps = {
  children?: React.ReactNode
  className?: string
}

export const Slides: AppFunctionComponent<SlidesProps> = ({
  children,
  className,
}) => {
  const { index, setCount } = useSlider()

  useLayoutEffect(() => setCount(Children.count(children)), [])

  return (
    <>
      {Children.map(children, (child, i) => {
        return (
          <Slide key={i} active={i === index} className={className}>
            {child}
          </Slide>
        )
      })}
    </>
  )
}

export const Navigation: AppFunctionComponent<{ title: string }> = ({
  className,
  title,
}) => {
  return (
    <SliderNavigation {...useSlider()} className={className} title={title} />
  )
}

export const FullSizeSliderNavigation = () => {
  return <SliderArrows {...useSlider()} />
}

export const DotsNavigation = () => {
  return <SliderDots {...useSlider()} />
}

const Container = styled.div`
  display: grid;
`

interface SliderProps {
  initIndex?: number
  title?: string
}

export const Slider: AppFunctionComponent<SliderProps> = ({
  children,
  initIndex = 0,
  title,
  className,
}) => {
  const [index, onIndexChange] = useState(initIndex)
  const [count, setCount] = useState(-1)
  const context = { index, onIndexChange, count, setCount }

  return (
    <SliderContext.Provider value={context}>
      <Container
        className={className}
        role="slider"
        aria-valuemin={1}
        aria-valuemax={count}
        aria-valuenow={index + 1}
        aria-label={title}
      >
        {children}
      </Container>
    </SliderContext.Provider>
  )
}
