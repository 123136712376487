import React from "react"
import styled, { css } from "styled-components"
import { AppFunctionComponent } from "../../../types"
import { NavigationArrow } from "../general-styles.component"

const NavigationWrapper = styled.nav`
  display: flex;
  align-items: center;
  flex-direction: row;

  p {
    display: block;
    padding: 0 10px;
    min-width: 35px;
    text-align: center;
    font-size: ${14 / 16}rem;
    letter-spacing: 0.2em;
  }
`

const ButtonWrapper = styled.button<{ previous?: boolean }>`
  background: none;
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;

  ${({ previous }) =>
    previous &&
    css`
      ${NavigationArrow} {
        transform: rotateZ(180deg);
      }
    `}
`

interface Navigation {
  count: number
  index: number
  onIndexChange: (index: number) => void
  title: string
}

const SliderNavigation: AppFunctionComponent<Navigation> = ({
  className = "",
  count,
  index,
  onIndexChange,
  title,
}) => {
  const prev = () => {
    if (index > 0) {
      onIndexChange(index - 1)
    }
  }

  const next = () => {
    if (index < count - 1) {
      onIndexChange(index + 1)
    }
  }
  return (
    <NavigationWrapper className={className}>
      <ButtonWrapper
        onClick={prev}
        aria-label={`Go to previous slide of ${title}`}
        aria-disabled={Boolean(index === 0)}
        previous
      >
        <NavigationArrow $inactive={index === 0} aria-hidden="true" />
      </ButtonWrapper>

      <p>
        {index + 1}/{count}
      </p>
      <ButtonWrapper
        onClick={next}
        aria-label={`Go to next slide of ${title}`}
        aria-disabled={Boolean(index === count - 1)}
      >
        <NavigationArrow $inactive={index === count - 1} aria-hidden="true" />
      </ButtonWrapper>
    </NavigationWrapper>
  )
}

export default SliderNavigation
