import React from "react"
import styled from "styled-components"
import { AppFunctionComponent } from "../../../types"
import { ArrowContainer, ArrowIcon } from "../slider.component"

const ArrowPrev = styled(ArrowContainer)`
  left: 0;

  svg {
    transform: rotateZ(180deg);
  }
`

const ArrowNext = styled(ArrowContainer)`
  right: 0;
`

export interface Navigation {
  count: number
  index: number
  onIndexChange: (index: number) => void
}

const SliderArrows: AppFunctionComponent<Navigation> = ({
  count,
  index,
  onIndexChange,
}) => {
  const indexHigherThanZero = index > 0
  const indexLowerThanLastItem = index < count - 1

  const prev = () => indexHigherThanZero && onIndexChange(index - 1)

  const next = () => indexLowerThanLastItem && onIndexChange(index + 1)

  return (
    <>
      <ArrowPrev
        onClick={prev}
        active={indexHigherThanZero}
        data-testid="prev-arrow"
      >
        <ArrowIcon />
      </ArrowPrev>
      <ArrowNext
        onClick={next}
        active={indexLowerThanLastItem}
        data-testid="next-arrow"
      >
        <ArrowIcon />
      </ArrowNext>
    </>
  )
}

export default SliderArrows
