import React, { ReactNode } from "react"
import styled from "styled-components"
import { generateSources } from "../../helpers/images"
import RichText from "../../resolvers/rich-text/rich-text.resolver"
import {
  AppFunctionComponent,
  GatsbyImage,
  RenderRichText,
  ResponsiveImage,
} from "../../types"
import { SectionImage } from "./sections/section.component"

export interface Slide {
  image?: ResponsiveImage | GatsbyImage
  title?: string
  description?: RenderRichText
}

interface Props {
  slide: Slide
  className?: string
}

const SlideImage = styled(SectionImage)`
  border-radius: 5px;
  pointer-events: none;
  box-shadow: 0 2px 20px 0 ${({ theme }) => theme.color.lightShadow};
  margin-bottom: 50px;
`

const SlideIcon = styled.img`
  grid-area: Image;
  min-height: 200px;
  align-self: baseline;
  margin-bottom: 50px;
`

const SlideItem = styled.div`
  display: flex;
  flex-direction: column;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-left: 20px;
    padding-right: 40px;
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const resolveImage = (image: ResponsiveImage | GatsbyImage): ReactNode => {
  if (
    (image as ResponsiveImage).mobileImage ||
    (image as ResponsiveImage).tabletImage ||
    (image as ResponsiveImage).desktopImage
  ) {
    return (
      <SlideImage
        disableShadow
        image={generateSources([
          [(image as ResponsiveImage).desktopImage],
          [(image as ResponsiveImage).tabletImage, 1280],
          [(image as ResponsiveImage).mobileImage, 500],
        ])}
      />
    )
  }
  if ((image as GatsbyImage).gatsbyImageData) {
    return (
      <SlideImage
        disableShadow
        image={(image as GatsbyImage).gatsbyImageData}
      />
    )
  }
  return <SlideIcon src={(image as GatsbyImage).file.url} alt={""} />
}

const SliderItem: AppFunctionComponent<Props> = ({ className = "", slide }) => {
  const { description, image } = slide
  return (
    <SlideItem className={className}>
      {image && resolveImage(image)}
      {description && <RichText content={description} />}
    </SlideItem>
  )
}

export default SliderItem
